import { PropsWithChildren, useEffect, useMemo, useState } from 'react';

import { getAuth, onAuthStateChanged } from 'firebase/auth';

import { Spinner } from '@/components/spinner';
import { UserContext } from '@/contexts/user/user.context';

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const [isFirebaseLoaded, setIsFirebaseLoaded] = useState(false);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

  useEffect(() => {
    onAuthStateChanged(getAuth(), (user) => {
      setIsFirebaseLoaded(true);
      setIsUserLoggedIn(user !== null);
    });
  }, []);

  const isPrivateRoutesAllowed = isFirebaseLoaded && isUserLoggedIn;

  const contextValue = useMemo(
    () => ({
      isFirebaseLoaded,
      isUserLoggedIn,
      isPrivateRoutesAllowed,
    }),
    [isFirebaseLoaded, isUserLoggedIn, isPrivateRoutesAllowed],
  );

  if (!isFirebaseLoaded) {
    return <Spinner className="h-screen w-screen" />;
  }

  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
};
