import { useMutation } from '@tanstack/react-query';

import { updateChargeSite, UpdateChargeSiteData } from '@/open-api/_api';
import { QUERY_KEY } from '@/tanstack/query-keys.const.ts';
import { queryClient } from '@/tanstack/use-query.setup.ts';

type Payload = {
  chargeSiteId: number;
  body: UpdateChargeSiteData['body'];
};

export const useUpdateChargeSiteMutation = () =>
  useMutation({
    mutationFn: ({ chargeSiteId, body }: Payload) =>
      updateChargeSite({ body, path: { id: chargeSiteId } }).then(({ data }) => data),

    onMutate: async ({ chargeSiteId }) => {
      await Promise.all([
        queryClient.cancelQueries({
          queryKey: [QUERY_KEY.CHARGE_SITE_LIST],
        }),
        queryClient.cancelQueries({
          queryKey: [QUERY_KEY.CHARGE_SITE_ITEM, chargeSiteId],
        }),
      ]);
    },

    onSettled: async (_, __, { chargeSiteId }) => {
      await Promise.all([
        queryClient.refetchQueries({
          queryKey: [QUERY_KEY.CHARGE_SITE_LIST],
        }),
        queryClient.refetchQueries({
          queryKey: [QUERY_KEY.CHARGE_SITE_ITEM, chargeSiteId],
        }),
      ]);
    },
  });
