import { useEffect } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { LeafletEventHandlerFnMap } from 'leaflet';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { z } from 'zod';

import { ErrorText } from '@/components/error-text';
import { LocationMarker } from '@/components/location-marker';
import { MapComponent } from '@/components/map';
import { isDefined } from '@/lib/utils';
import { CreateChargeSiteData, UpdateChargeSiteData } from '@/open-api/_api';
import { Button } from '@/shadcn/ui/button';
import { Input } from '@/shadcn/ui/input';
import { Textarea } from '@/shadcn/ui/textarea';

const chargeSiteFormSchema = z.object({
  title: z.string().min(1),
  description: z.string().optional(),
  address: z.string().optional(),
  latitude: z.number({ message: 'Location is required' }),
  longitude: z.number({ message: 'Location is required' }),
});

type ChargeSiteFormSchemaType = z.infer<typeof chargeSiteFormSchema>;

type Props = {
  isLoading?: boolean;
  defaultValues?: UpdateChargeSiteData['body'] | CreateChargeSiteData['body'];
  onSubmit: SubmitHandler<ChargeSiteFormSchemaType>;
};

export const ChargeSiteForm = ({ isLoading, defaultValues, onSubmit }: Props) => {
  const { register, handleSubmit, formState, watch, setValue, reset } =
    useForm<ChargeSiteFormSchemaType>({
      resolver: zodResolver(chargeSiteFormSchema),
      defaultValues,
    });

  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  const handleMapClick: LeafletEventHandlerFnMap['click'] = ({ latlng }) => {
    setValue('latitude', latlng.lat, { shouldValidate: true });
    setValue('longitude', latlng.lng, { shouldValidate: true });
  };

  const position = {
    lat: watch('latitude'),
    lng: watch('longitude'),
  };

  const isLocationMarkerVisible = isDefined(position.lat) && isDefined(position.lng);

  return (
    <>
      <div className="text-center py-4 text-xs my-16 text-muted">
        Please provide a title and description for your location
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col bg-background-muted -mx-6 p-6"
      >
        <div className="flex gap-6">
          <div className="w-1/2 flex flex-wrap gap-6 ">
            <div className="flex flex-col flex-1 gap-12 h-full">
              <Input
                {...register('title')}
                placeholder="Title"
                className="h-12 bg-background"
                error={formState.errors.title?.message}
                disabled={isLoading}
              />
              <Textarea
                {...register('description')}
                placeholder="Description. Few words about your place"
                className="h-24 bg-background"
                error={formState.errors.description?.message}
                disabled={isLoading}
              />
              <div className="relative">
                {/* <span className="absolute inset-y-0 left-3 flex items-center text-muted">
                  <img src={SearchIcon} alt="Search icon" />
                </span> */}
                <Input
                  {...register('address')}
                  placeholder="Address"
                  className="h-12 pl-12 bg-background"
                  error={formState.errors.address?.message}
                  disabled={isLoading}
                />
              </div>
            </div>
          </div>
          <div className="w-full">
            <MapComponent onMapClick={handleMapClick}>
              {isLocationMarkerVisible && <LocationMarker position={position} />}
            </MapComponent>
            <ErrorText>
              {formState.errors.latitude?.message ?? formState.errors.longitude?.message}
            </ErrorText>
          </div>
        </div>
        <div className="flex justify-between mt-8">
          <Link to="/locations" className="mr-4">
            <Button type="button" variant="outline">
              Cancel
            </Button>
          </Link>
          <Button
            type="submit"
            className="min-w-48"
            isLoading={formState.isSubmitting}
            disabled={isLoading}
          >
            Next
          </Button>
        </div>
      </form>
    </>
  );
};
