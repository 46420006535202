import { PropsWithChildren, ReactNode } from 'react';

type Props = PropsWithChildren<{
  title: string;
  hasScroll?: boolean;
  rightSlot?: ReactNode;
}>;

export const PageLayout = ({ children, rightSlot, title, hasScroll = false }: Props) => {
  return (
    <div className={`relative h-screen  ${hasScroll ? 'overflow-y-auto' : 'overflow-hidden'}`}>
      <div className="py-9 pl-9 ml-44 flex items-center justify-between fixed inset-x-6 z-aboveMap bg-background h-28">
        <h1 className="text-3xl font-medium capitalize pl-3">{title}</h1>
        <div>{rightSlot}</div>
      </div>
      <div
        className={`px-6 pt-28 w-full ${hasScroll ? 'h-fit' : 'h-screen flex flex-col justify-between'}`}
      >
        {children}
      </div>
    </div>
  );
};
