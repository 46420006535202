import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';

import ArrowIcon from '@/assets/icons/arrow.svg';
import ConnectorCCSIcon from '@/assets/icons/connectors/ccs.svg';
import ConnectorType1Icon from '@/assets/icons/connectors/type-1.svg';
import ConnectorType2Icon from '@/assets/icons/connectors/type-2.svg';
import { ChargePointDto } from '@/common.types.ts';
import { CONNECTOR_TYPES } from '@/core.const';
import { fromWattToKiloWatt } from '@/lib/utils';
import { Table, TableRow } from '@/shadcn/ui/table';

const connectorTypeIconsMap: Record<number, string> = {
  [CONNECTOR_TYPES.CCS]: ConnectorCCSIcon,
  [CONNECTOR_TYPES.TYPE1]: ConnectorType1Icon,
  [CONNECTOR_TYPES.TYPE2]: ConnectorType2Icon,
};

const columns: ColumnDef<ChargePointDto>[] = [
  {
    accessorKey: 'title',
    cell: (info) => (
      <span className="font-figtree font-text-sm pl-4 w-48">{info.row.original.title}</span>
    ),
  },
  {
    accessorKey: 'power',
    cell: (info) => {
      const power =
        info.row.original.maxConnectorPowerWt === info.row.original.minConnectorPowerWt
          ? `${fromWattToKiloWatt(info.row.original.minConnectorPowerWt)} kW`
          : `${fromWattToKiloWatt(info.row.original.minConnectorPowerWt)} - ${fromWattToKiloWatt(info.row.original.maxConnectorPowerWt)} kW`;

      return <span className="font-medium text-lg w-48">{power}</span>;
    },
  },
  {
    accessorKey: 'connectors',
    cell: (info) => (
      <div className="flex gap-2 p-2">
        {info.row.original.connectorTypes.map((connectorType) => (
          <img
            key={connectorType}
            src={connectorTypeIconsMap[connectorType]}
            alt="connector icon"
          />
        ))}
      </div>
    ),
  },
  {
    accessorKey: 'actions',
    cell: () => <img src={ArrowIcon} alt="arrow right icon" className="p-3 ml-auto mr-2.5" />,
  },
];

type Props = {
  data: ChargePointDto[];
  standalone?: boolean;
};

export const ChargePointsTable = ({ data, standalone = false }: Props) => {
  const navigate = useNavigate();
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const handleNavigateCharegPoint = (chargePointId: number) => {
    navigate(`/charge-point/${chargePointId}`);
  };

  return (
    <Table className="bg-background">
      {table.getRowModel().rows.map((row) => (
        <TableRow
          key={row.id}
          className={`cursor-pointer border-2 border-borderLight transition-colors last:rounded-b-md hover:bg-muted/20 ${standalone ? 'first:rounded-t-md odd:bg-background-muted' : 'even:bg-background-muted'}`}
          onClick={() => handleNavigateCharegPoint(row.original.id)}
        >
          {row
            .getVisibleCells()
            .map((cell) => flexRender(cell.column.columnDef.cell, cell.getContext()))}
        </TableRow>
      ))}
    </Table>
  );
};
