import { useEffect } from 'react';

import { is404Error, isForbiddenError, isUnauthorizedError } from '@/lib/utils';

import { ErrorLayout } from './error-layout';

type Props = { error: unknown; resetError: () => void };

export const ErrorFallback = ({ error, resetError }: Props) => {
  useEffect(() => {
    console.error(error);
  }, [error]);

  if (is404Error(error)) {
    return <ErrorLayout resetError={resetError} />;
  }

  if (isUnauthorizedError(error) || isForbiddenError(error)) {
    return (
      <ErrorLayout
        title="Access Denied:"
        description="Nice try, but this door only opens for wizards and cats."
        resetError={resetError}
      />
    );
  }

  return (
    <ErrorLayout
      title="Something went wrong"
      description="Application has encountered an error. Please try again later."
      resetError={resetError}
    />
  );
};
