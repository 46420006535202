import { useEffect } from 'react';

import { getAuth } from 'firebase/auth';
import { useSearchParams } from 'react-router-dom';

import NotInvitedEmailSvg from '../../assets/images/not-invited-email.svg';

export const UserNotInvitedPagePage = () => {
  const [params] = useSearchParams();
  const email = params.get('email') ?? 'email';

  useEffect(() => {
    getAuth()
      .signOut()
      .then(() => {
        console.log('User signed out');
        window.localStorage.removeItem('emailForSignIn');
      });
  }, []);

  return (
    <>
      <img src={NotInvitedEmailSvg} alt="Confirmation Icon" className="mx-auto mt-10" />
      <div className="text-muted mt-10">
        The <span className="text-link">{email}</span> is not invited to the system. Please ask your
        administrator for an invitation
      </div>
      <div className="text-muted my-10">
        If you think it&apos;s a mistake please{' '}
        <a className="text-link hover:underline" href="mailto:milosz.cisowski@ion-dev.com">
          contact us
        </a>
      </div>
    </>
  );
};
