import { useMutation } from '@tanstack/react-query';

import { deleteChargeSite } from '@/open-api/_api';
import { QUERY_KEY } from '@/tanstack/query-keys.const.ts';
import { queryClient } from '@/tanstack/use-query.setup.ts';

export const useDeleteChargeSiteMutation = () => {
  return useMutation({
    mutationFn: (chargeSiteId: number) =>
      deleteChargeSite({ path: { id: chargeSiteId } }).then(({ data }) => data),

    onSettled: async () => {
      await queryClient.refetchQueries({
        queryKey: [QUERY_KEY.CHARGE_SITE_LIST],
      });

      await queryClient.refetchQueries({
        queryKey: [QUERY_KEY.CHARGE_SITE_STATISTIC],
      });
    },
  });
};
