import ChargerIcon from '@/assets/icons/charger.svg?react';
import LocationsIcon from '@/assets/icons/locations.svg?react';
import { Widget } from '@/components/widget.tsx';
import { useChargeSitesStatistics } from '@/pages/charge-site/hooks/use-charge-sites-statistics.query';
import { Skeleton } from '@/shadcn/ui/skeleton';

export const ChargeSitesStatistics = () => {
  const { data: statistics, isLoading } = useChargeSitesStatistics();

  const loader = (
    <>
      <Skeleton height={'widget'} width={'widget'} />
      <Skeleton height={'widget'} width={'widget'} />
    </>
  );

  const content = (
    <>
      <Widget
        title="Locations"
        content={statistics?.chargeSitesCount}
        reactIcon={<LocationsIcon />}
        contentProps={{ size: '5xl' }}
      />
      <Widget
        title="Chargers"
        content={statistics?.chargePointsCount}
        reactIcon={<ChargerIcon />}
        contentProps={{ size: '5xl' }}
      />
    </>
  );

  return <div className="flex justify-start gap-4 pb-4">{isLoading ? loader : content}</div>;
};
