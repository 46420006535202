import { ReactNode } from 'react';

import { cn } from '@/lib/utils';

type IconProps = {
  /** Inline SVG icon imported with "?react" passed as JSX */
  reactIcon: ReactNode;
  className?: string;
};
/**Use this for icons created by designer using strokes. It will take the outer font color by default*/
export const Icon = ({ reactIcon, className }: IconProps) => {
  return <div className={cn('[&>svg_*]:stroke-current', className)}>{reactIcon}</div>;
};
