import { getAuth } from 'firebase/auth';
import { Navigate, Outlet, useSearchParams } from 'react-router-dom';

import { Card } from '@/shadcn/ui/card';

import LoginIonLogo from '../../assets/logos/login-ion-logo.svg';

export const LoginLayout = () => {
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get('redirectTo') ?? '/';

  if (getAuth().currentUser) {
    return <Navigate to={redirectTo} />;
  }

  return (
    <div className="bg-background-muted h-screen flex flex-col items-center pt-16">
      <img src={LoginIonLogo} alt="Logo" />
      <Card className="w-[512px] mt-6 py-10 px-6 text-center rounded-lg shadow-lg bg-background">
        <Outlet />
      </Card>
      <div className="absolute bottom-16 text-xs text-link">
        <a
          href="https://ion.ae/privacy-policy.html"
          className="hover:underline"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </a>{' '}
        \{' '}
        <a
          href="https://ion.ae/terms-of-service.html"
          className="hover:underline"
          target="_blank"
          rel="noreferrer"
        >
          Terms of Service
        </a>
      </div>
    </div>
  );
};
