import { HTMLAttributes } from 'react';

import { cva, VariantProps } from 'class-variance-authority';

import { cn } from '@/lib/utils';

const skeletonVariants = cva('flex animate-pulse rounded-md', {
  variants: {
    width: {
      auto: 'w-full',
      widget: 'w-52',
    },
    height: {
      auto: 'h-full',
      widget: 'h-32',
      doubleWidget: 'h-64',
      tableRow: 'h-14',
    },
    gradientOrigin: {
      left: 'bg-gradient-to-r from-muted to-background-muted',
      right: 'bg-gradient-to-r from-background-muted to-muted',
      center: 'bg-gradient-to-r from-background-muted via-muted to-background-muted',
    },
  },
  defaultVariants: {
    width: 'auto',
    height: 'auto',
    gradientOrigin: 'left',
  },
});

export interface SkeletonProps
  extends HTMLAttributes<HTMLElement>,
    VariantProps<typeof skeletonVariants> {}

function Skeleton({ className, width, height, gradientOrigin, ...props }: SkeletonProps) {
  return (
    <div
      className={cn(skeletonVariants({ width, height, gradientOrigin }), className)}
      {...props}
    />
  );
}

export { Skeleton };
