import * as Sentry from '@sentry/react';
import { createBrowserRouter } from 'react-router-dom';

import { LoginLayout } from '@/common/root/login-layout.tsx';
import { PrivateRoutesAllowedWrapper } from '@/common/root/private-routes-allowed-wrapper.tsx';
import { AddChargeSitePage } from '@/pages/charge-site/add-charge-site.page';
import { ChargeSitePage } from '@/pages/charge-site/charge-site.page';
import { ChargeSiteListPage } from '@/pages/charge-site/charge-site-list.page';
import { EmailLinkLoginPage } from '@/pages/login/email-link-login-page/email-link-login-page';
import { EmailLinkSentPage } from '@/pages/login/email-link-sent-page.tsx';
import { UserNotInvitedPagePage } from '@/pages/login/user-not-invited-page.tsx';

import { AppReadyWrapper } from './common/root/app-ready-wrapper';
import { ErrorLayout } from './common/root/error-layout';
import { EditChargeSitePage } from './pages/charge-site/edit-charge-site.page';
import { DashboardPage } from './pages/dashboard/dashboard-page';
import { UIExample } from './pages/ui-example/ui-example';

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter([
  {
    path: '/',
    element: <AppReadyWrapper />,
    children: [
      {
        path: '/',
        element: <PrivateRoutesAllowedWrapper />,
        children: [
          {
            path: '/',
            element: <DashboardPage />,
          },
          {
            path: '/locations',
            element: <ChargeSiteListPage />,
          },
          {
            path: '/tariffs',
            element: <>Tariffs</>,
          },
          {
            path: '/locations/add',
            element: <AddChargeSitePage />,
          },
          {
            path: '/locations/:chargeSiteId',
            element: <ChargeSitePage />,
          },
          {
            path: '/locations/:chargeSiteId/edit',
            element: <EditChargeSitePage />,
          },
          {
            path: '/billing',
            element: <>billing</>,
          },
          {
            path: '/users',
            element: <>users</>,
          },
          {
            path: '/ui-example',
            element: <UIExample />,
          },
        ],
      },
      {
        path: '/login',
        element: <LoginLayout />,
        children: [
          {
            path: '/login',
            element: <EmailLinkLoginPage />,
          },
          {
            path: '/login/sent',
            element: <EmailLinkSentPage />,
          },
          {
            path: '/login/not-invited',
            element: <UserNotInvitedPagePage />,
          },
        ],
      },
    ],
  },
  { path: '*', element: <ErrorLayout /> },
]);
