import { Link } from 'react-router-dom';

import { PageLayout } from '@/common/root/page-layout';
import { ChargeSitesTable } from '@/components/charge-sites-table';
import { CpmsChargeSiteListResponseDto } from '@/open-api/_api';
import { ChargeSitesEmpty } from '@/pages/charge-site/components/charge-sites-empty';
import { ChargeSitesStatistics } from '@/pages/charge-site/components/charge-sites-statistics';
import { useChargeSiteList } from '@/pages/charge-site/hooks/use-charge-site-list.query';
import { Button } from '@/shadcn/ui/button.tsx';
import { Skeleton } from '@/shadcn/ui/skeleton';

type Props = {
  data?: CpmsChargeSiteListResponseDto;
};

const LocationsPageContent = ({ data }: Props) => {
  if (data?.totalItems === 0) {
    return <ChargeSitesEmpty />;
  }

  return data ? (
    <ChargeSitesTable data={data.items} />
  ) : (
    <div className="flex flex-col gap-4">
      <Skeleton height={'tableRow'} />
      <Skeleton height={'tableRow'} />
    </div>
  );
};

export const ChargeSiteListPage = () => {
  const { data } = useChargeSiteList({
    page: '1',
    take: '100',
  });

  return (
    <PageLayout
      title="Locations"
      rightSlot={
        <Link to="/locations/add">
          <Button variant="outline">Add New</Button>
        </Link>
      }
      hasScroll
    >
      <ChargeSitesStatistics />
      <LocationsPageContent data={data} />
    </PageLayout>
  );
};
