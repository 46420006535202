import { zodResolver } from '@hookform/resolvers/zod';
import { SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';

import { Button } from '@/shadcn/ui/button';
import { Input } from '@/shadcn/ui/input';

const magicLinkLoginFormSchema = z.object({
  email: z.string().email({ message: 'Please use valid email' }),
});

const useFormOptions = {
  resolver: zodResolver(magicLinkLoginFormSchema),
  defaultValues: {
    email: '',
  },
};

type MagicLinkLoginFormSchemaType = z.infer<typeof magicLinkLoginFormSchema>;

type Props = {
  onSubmit: SubmitHandler<MagicLinkLoginFormSchemaType>;
};

export const MagicLinkLoginForm = ({ onSubmit }: Props) => {
  const { register, formState, handleSubmit } =
    useForm<MagicLinkLoginFormSchemaType>(useFormOptions);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="px-16">
      <Input
        {...register('email')}
        placeholder="Enter your email here"
        className={'mt-10 h-14'}
        error={formState.errors.email?.message}
      />
      <p className="text-xs my-10 text-muted">
        By pressing this button, I acknowledge and agree to the terms of the company.
      </p>
      <Button type="submit" size="fullWidth" isLoading={formState.isSubmitting}>
        Send Magic Link
      </Button>
    </form>
  );
};
