import { QueryCache, QueryClient } from '@tanstack/react-query';

import { ERROR_SHORT_CODES } from '@/core.const';
import { HttpException } from '@/open-api/http.exception';
import { showErrorToast } from '@/shadcn/hooks/use-toast';

import {
  isForbiddenError,
  isInternalServerError,
  isNotFoundError,
  isUnauthorizedError,
} from '../open-api/http.util';

type IonError = HttpException & { body: { shortCode: string; message: string } };

declare module '@tanstack/react-query' {
  interface Register {
    defaultError: IonError;
  }
}

const shouldThrowOnError = (error: unknown) => {
  return (
    isNotFoundError(error) ||
    isUnauthorizedError(error) ||
    isForbiddenError(error) ||
    isInternalServerError(error)
  );
};

const getErrorMessage = (shortCode: string | undefined) => {
  switch (shortCode) {
    case ERROR_SHORT_CODES.CHARGE_SITE.DUPLICATE_TITLE: {
      return 'Location with this title already exists';
    }

    // return if error should be ommited, added to fix types and as an example
    case ERROR_SHORT_CODES.USER_BALANCE.NOT_FOUND: {
      return;
    }

    default: {
      return 'Something wrong, please try again';
    }
  }
};

const onError = (error: IonError) => {
  console.error({ ...error });
  const errorMessage = getErrorMessage(error.body.shortCode);

  if (errorMessage) {
    showErrorToast(errorMessage);
  }
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      throwOnError: shouldThrowOnError,
      retry: 1,
    },
    mutations: {
      throwOnError: shouldThrowOnError,
      onError,
    },
  },
  queryCache: new QueryCache({ onError }),
});
