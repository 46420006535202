import { type ClassValue, clsx } from 'clsx';
import isNil from 'lodash/isNil';
import isObject from 'lodash/isObject';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const cc = (condition: boolean, className: string): string =>
  condition ? ` ${className}` : '';

export function isDefined<T>(v: T | null | undefined): v is NonNullable<T> {
  return !isNil(v);
}

export const fromWattToKiloWatt = (powerWt: number = 0, decimalPoints = 3) => {
  const powerKW = Number(powerWt) / 1000;

  const formattedPower = powerKW.toFixed(decimalPoints);

  if (formattedPower === '0.000') {
    return '0';
  }

  return Number.isInteger(powerKW)
    ? `${Number.parseInt(formattedPower)}`
    : `${Number.parseFloat(formattedPower)}`;
};

export const isHttpError = (error: unknown) => isObject(error) && 'status' in error;
export const isUnauthorizedError = (error: unknown) => isHttpError(error) && error.status === 401;
export const isForbiddenError = (error: unknown) => isHttpError(error) && error.status === 403;
export const is404Error = (error: unknown) => isHttpError(error) && error.status === 404;
export const isInternalServerError = (error: unknown) => isHttpError(error) && error.status === 500;
