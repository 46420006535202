import { Icon as LIcon, LatLng } from 'leaflet';
import { Marker } from 'react-leaflet';

import PositionIcon from '../assets/icons/position.svg';

interface Props {
  position: Pick<LatLng, 'lat' | 'lng'>;
}

export const LocationMarker = ({ position }: Props) => {
  const customIcon = new LIcon({
    iconUrl: PositionIcon,
    iconSize: [41, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  return <Marker position={position} icon={customIcon} />;
};
