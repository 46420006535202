import { PropsWithChildren } from 'react';

import { LatLng, LeafletEventHandlerFnMap } from 'leaflet';
import { MapContainer, TileLayer, useMapEvent } from 'react-leaflet';

import { MAP_SOURCE_URL, MAP_START_POSITION } from '@/core.const';
import { Card } from '@/shadcn/ui/card';

type MapComponentProps = {
  onMapClick?: LeafletEventHandlerFnMap['click'];
  center?: Pick<LatLng, 'lat' | 'lng'>;
};

const MapInnerComponent = ({ children, onMapClick }: PropsWithChildren<MapComponentProps>) => {
  useMapEvent('click', onMapClick);

  return (
    <>
      <TileLayer
        url={MAP_SOURCE_URL || ''}
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        tileSize={512}
        zoomOffset={-1}
      />
      {children}
    </>
  );
};

export const MapComponent = ({
  children,
  onMapClick,
  center,
}: PropsWithChildren<MapComponentProps>) => {
  return (
    <Card className="h-full w-full overflow-hidden">
      <MapContainer zoom={12} center={center ?? MAP_START_POSITION} className="w-full h-full">
        <MapInnerComponent onMapClick={onMapClick}>{children}</MapInnerComponent>
      </MapContainer>
    </Card>
  );
};
