import { useMemo } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { PageLayout } from '@/common/root/page-layout';
import { UpdateChargeSiteData } from '@/open-api/_api';
import { ChargeSiteForm } from '@/pages/charge-site/components/charge-site-form';

import { useUpdateChargeSiteMutation } from './hooks/update-charge-site.mutation';
import { useChargeSiteData } from './hooks/use-charge-site-data.query';

export const EditChargeSitePage = () => {
  const { chargeSiteId } = useParams();
  const { data: chargeSite, isLoading } = useChargeSiteData(Number(chargeSiteId));
  const navigate = useNavigate();
  const { mutateAsync: updateChargeSite } = useUpdateChargeSiteMutation();

  const handleSubmit = async (body: UpdateChargeSiteData['body']) => {
    if (!chargeSiteId) {
      return;
    }
    await updateChargeSite({ chargeSiteId: Number(chargeSiteId), body });
    navigate(`/locations/${chargeSiteId}`);
  };

  const defaultValues = useMemo(
    () =>
      chargeSite && {
        ...chargeSite,
        subTitle: chargeSite.subTitle ?? undefined,
        address: chargeSite.address ?? undefined,
        description: chargeSite.description ?? undefined,
      },
    [chargeSite],
  );

  return (
    <PageLayout title="Edit Location">
      <ChargeSiteForm defaultValues={defaultValues} onSubmit={handleSubmit} isLoading={isLoading} />
    </PageLayout>
  );
};
