import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';

import { CpmsChargeSiteListResponseDto } from '@/open-api/_api';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/shadcn/ui/accordion';
import { Table, TableRow } from '@/shadcn/ui/table';

import { ChargePointsTable } from './charge-points-table';

type ChargeSite = CpmsChargeSiteListResponseDto['items'][number];

const columns: ColumnDef<ChargeSite>[] = [
  {
    accessorKey: 'title',
    cell: (info) => {
      return (
        <div className="p-2 pl-6 w-96">
          <p className="font-medium text-lg leading-7">{info.row.original.title}</p>
          <p className="leading-4 text-muted font-figtree text-xs">{info.row.original.address}</p>
        </div>
      );
    },
  },
  {
    accessorKey: 'chargePointsCount',
    cell: (info) => {
      const chargePointsCount = info.row.original.chargePointsCount;

      return (
        <p className="text-lg text-muted p-4">
          {chargePointsCount} charger{chargePointsCount > 1 || chargePointsCount === 0 ? 's' : ''}
        </p>
      );
    },
  },
  {
    accessorKey: 'actions',
    cell: (info) =>
      info.row.original.chargePointsCount > 0 && (
        <AccordionTrigger
          className="flex items-center justify-center border rounded-sm border-primary-light"
          onClick={(event) => event.stopPropagation()}
        />
      ),
  },
];

type Props = {
  data: ChargeSite[];
};

export const ChargeSitesTable = ({ data }: Props) => {
  const navigate = useNavigate();
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const handleNavigateToChargeSite = (chargeSiteId: number) => {
    navigate(`/locations/${chargeSiteId}`);
  };

  return (
    <Accordion type="single" collapsible>
      <Table>
        {table.getRowModel().rows.map((row) => (
          <AccordionItem
            value={row.id}
            key={row.id}
            className="bg-background-muted rounded-md mb-4 transition-colors hover:bg-muted/50"
          >
            <TableRow
              className="cursor-pointer"
              onClick={() => handleNavigateToChargeSite(row.original.id)}
            >
              {row
                .getVisibleCells()
                .map((cell) => flexRender(cell.column.columnDef.cell, cell.getContext()))}
            </TableRow>
            <AccordionContent className="rounded-b-md">
              <ChargePointsTable data={row.original.chargePoints} standalone={false} />
            </AccordionContent>
          </AccordionItem>
        ))}
      </Table>
    </Accordion>
  );
};
