// import { Link } from 'react-router-dom';

import AddChargersSvg from '@/assets/images/add-chargers.svg';
import { ChargePointDto } from '@/common.types.ts';
import { ChargePointsTable } from '@/components/charge-points-table';
import { Button } from '@/shadcn/ui/button.tsx';

type Props = {
  // chargeSiteId: number;
  data: ChargePointDto[];
};

export const ChargeSiteChargePointList = ({ data }: Props) => {
  if (data.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center h-full my-16 gap-6">
        <img src={AddChargersSvg} alt="charger-icon" />

        {/* <Link to={`/locations/${chargeSiteId}/add-chargers`}> */}
        <Button variant="secondary">Add Chargers</Button>
        {/* </Link> */}
      </div>
    );
  }

  return (
    <>
      <ChargePointsTable data={data} standalone={true} />

      {/* <Link to={`/locations/${chargeSiteId}/add-chargers`}> */}
      <Button className="mt-6" size="fullWidth" variant="secondary">
        Add Chargers
      </Button>
      {/* </Link> */}
    </>
  );
};
