import { Link, useNavigate } from 'react-router-dom';

import ArchiveLocationIcon from '@/assets/icons/archive-location.svg?react';
import EditLocationIcon from '@/assets/icons/edit-location.svg?react';
import GearIcon from '@/assets/icons/gear.svg?react';
import { Icon } from '@/components/icon';
import { Modal } from '@/components/modal.tsx';
import { useDeleteChargeSiteMutation } from '@/pages/charge-site/hooks/delete-charge-site.mutation';
import { Button } from '@/shadcn/ui/button.tsx';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/shadcn/ui/dropdown-menu.tsx';

type Props = {
  chargeSiteId: number;
  chargeSiteTitle: string;
};

export const ChargeSiteMenuSettings = ({ chargeSiteId, chargeSiteTitle }: Props) => {
  const navigate = useNavigate();
  const { mutateAsync: deleteChargeSite, isPending } = useDeleteChargeSiteMutation();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="text">
          Location Settings
          <Icon reactIcon={<GearIcon />} />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem asChild>
          <Link to={`/locations/${chargeSiteId}/edit`}>
            <Icon reactIcon={<EditLocationIcon />} className="mr-2" />
            Edit Location
          </Link>
        </DropdownMenuItem>
        <Modal
          title="Archive Location"
          subtitle={chargeSiteTitle}
          variant="destructive"
          trigger={
            <DropdownMenuItem onSelect={(event) => event.preventDefault()}>
              <Icon reactIcon={<ArchiveLocationIcon />} className="mr-2" />
              Archive Location
            </DropdownMenuItem>
          }
          description={
            <div>
              Do you want to archive the location{' '}
              <span className="font-bold">{chargeSiteTitle}?</span> All associated charging points
              will go offline and will no longer appear in user searches.
            </div>
          }
          renderConfirmButton={(close) => (
            <Button
              className="min-w-48"
              onClick={async () => {
                await deleteChargeSite(chargeSiteId);
                close();
                navigate('/locations');
              }}
              isLoading={isPending}
            >
              Archive
            </Button>
          )}
        />
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
