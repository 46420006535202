import { useMutation } from '@tanstack/react-query';

import { createChargeSite, type CreateChargeSiteData } from '@/open-api/_api';
import { QUERY_KEY } from '@/tanstack/query-keys.const.ts';
import { queryClient } from '@/tanstack/use-query.setup.ts';

export const useCreateChargeSiteMutation = () =>
  useMutation({
    mutationFn: (body: CreateChargeSiteData['body']) =>
      createChargeSite({ body }).then(({ data }) => data),

    onMutate: async () => {
      await queryClient.cancelQueries({
        queryKey: [QUERY_KEY.CHARGE_SITE_LIST],
      });
    },

    onSettled: async () => {
      await queryClient.refetchQueries({
        queryKey: [QUERY_KEY.CHARGE_SITE_LIST],
      });
    },
  });
