import { Navigate, useSearchParams } from 'react-router-dom';

import ConfirmationEmailSvg from '../../assets/images/confirmation-email.svg';

export const EmailLinkSentPage = () => {
  const [params] = useSearchParams();
  const email = params.get('email');
  const emailForSignIn = localStorage.getItem('emailForSignIn');

  if (!email?.length || !emailForSignIn || email !== emailForSignIn) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      <img src={ConfirmationEmailSvg} alt="Confirmation Email Icon" className="mx-auto mt-10" />
      <h1 className="text-xl mt-10">Confirm your email address</h1>
      <div className="text-xs text-muted mt-2">Tap the button in the email that we sent to</div>
      <div className="text-xs text-link mb-10">{email}</div>
    </>
  );
};
