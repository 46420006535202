import { cloneElement, ReactElement, ReactNode, useState } from 'react';

import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogClose,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogSubtitle,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/shadcn/ui/alert-dialog';

type Props = {
  trigger: ReactNode;
  title: string;
  subtitle?: string;
  variant?: 'default' | 'destructive';
  description?: ReactNode;
  renderConfirmButton: (close: () => void) => ReactElement;
};

export const Modal = ({
  trigger,
  title,
  subtitle,
  description,
  variant = 'destructive',
  renderConfirmButton,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const AlertDialogAction = renderConfirmButton(() => setIsOpen(false));

  return (
    <AlertDialog open={isOpen} onOpenChange={setIsOpen}>
      <AlertDialogTrigger>{trigger}</AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle variant={variant}>
            {title}
            <AlertDialogClose />
          </AlertDialogTitle>
          {subtitle && <AlertDialogSubtitle>{subtitle}</AlertDialogSubtitle>}
        </AlertDialogHeader>
        <div className="p-10 pt-0">
          {description && <AlertDialogDescription>{description}</AlertDialogDescription>}
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            {cloneElement(AlertDialogAction, { variant })}
          </AlertDialogFooter>
        </div>
      </AlertDialogContent>
    </AlertDialog>
  );
};
