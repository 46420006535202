import { useParams } from 'react-router-dom';

import ChargerIcon from '@/assets/icons/charger.svg?react';
import DescriptionIcon from '@/assets/icons/description.svg?react';
import GeolocationIcon from '@/assets/icons/geolocation.svg?react';
import { PageLayout } from '@/common/root/page-layout.tsx';
import { LocationMarker } from '@/components/location-marker.tsx';
import { MapComponent } from '@/components/map.tsx';
import { Widget } from '@/components/widget.tsx';
import { isDefined } from '@/lib/utils';
import { ChargeSiteChargePointList } from '@/pages/charge-site/components/charge-site-charge-point-list';
import { ChargeSiteMenuSettings } from '@/pages/charge-site/components/charge-site-menu-settings';
import { useChargeSiteData } from '@/pages/charge-site/hooks/use-charge-site-data.query';
import { Skeleton } from '@/shadcn/ui/skeleton';

export const ChargeSitePage = () => {
  const { chargeSiteId } = useParams();
  const { data: chargeSite } = useChargeSiteData(Number(chargeSiteId));

  const coordinates = isDefined(chargeSite)
    ? {
        lat: chargeSite.latitude,
        lng: chargeSite.longitude,
      }
    : undefined;

  return (
    <PageLayout
      title={chargeSite?.title ?? ''}
      rightSlot={
        chargeSite && (
          <ChargeSiteMenuSettings chargeSiteId={chargeSite.id} chargeSiteTitle={chargeSite.title} />
        )
      }
      hasScroll
    >
      <div className="flex">
        <div className="w-1/2 flex-col gap-4">
          <div className="flex gap-4">
            {chargeSite ? (
              <>
                <Widget
                  fullWidth
                  title="Title"
                  content={<div className="min-h-12 flex items-end"> {chargeSite.title}</div>}
                  reactIcon={<ChargerIcon />}
                />
                <Widget
                  fullWidth
                  title="Coordinates"
                  reactIcon={<GeolocationIcon />}
                  content={
                    <span>
                      {chargeSite.latitude}
                      <br />
                      {chargeSite.longitude}
                    </span>
                  }
                />
              </>
            ) : (
              <>
                <Skeleton height="widget" />
                <Skeleton height="widget" gradientOrigin="right" />
              </>
            )}
          </div>
          <div className="flex my-4">
            {chargeSite ? (
              <Widget
                fullWidth
                reactIcon={<DescriptionIcon />}
                title="Description"
                content={chargeSite.description}
                contentProps={{ text: 'muted' }}
              />
            ) : (
              <Skeleton height="widget" gradientOrigin="center" />
            )}
          </div>
          <div className="flex gap-4">
            {chargeSite ? (
              <>
                <Widget
                  title="Chargers"
                  content={chargeSite.chargePoints.length.toString()}
                  reactIcon={<ChargerIcon />}
                  contentProps={{ size: '5xl' }}
                />
                <Widget
                  fullWidth
                  title="Average Utilization Rate"
                  content={'100%'}
                  contentProps={{ size: '5xl' }}
                />
              </>
            ) : (
              <>
                <Skeleton height="widget" />
                <Skeleton height="widget" gradientOrigin="right" />
              </>
            )}
          </div>
        </div>
        <div className="w-1/2 ml-4 z-0">
          <div className="h-full mb-4 ">
            {chargeSite ? (
              <MapComponent center={coordinates}>
                <LocationMarker
                  position={{ lat: chargeSite.latitude, lng: chargeSite.longitude }}
                />
              </MapComponent>
            ) : (
              <Skeleton gradientOrigin="center" />
            )}
          </div>
        </div>
      </div>
      <div className="my-6">
        {chargeSite ? (
          <ChargeSiteChargePointList data={chargeSite.chargePoints} />
        ) : (
          <>
            <Skeleton height="tableRow" />
            <Skeleton height="tableRow" gradientOrigin="center" className="mt-2" />
            <Skeleton height="tableRow" gradientOrigin="right" className="mt-2" />
          </>
        )}
      </div>
    </PageLayout>
  );
};
