import GearIcon from '@/assets/icons/gear.svg?react';
import { PageLayout } from '@/common/root/page-layout';
import { Icon } from '@/components/icon';
import { MapComponent } from '@/components/map';
import { Widget } from '@/components/widget';
import { Button } from '@/shadcn/ui/button';
import { Skeleton } from '@/shadcn/ui/skeleton';

export const UIExample = () => {
  return (
    <PageLayout title="UI DEMO" hasScroll>
      <div className="h-96 mb-4">
        <MapComponent />
      </div>
      <div className="flex gap-4 mb-4">
        <Widget
          title="Average Session Success Rate"
          content={'0%'}
          reactIcon={<GearIcon />}
          linkTo="/locations"
          contentProps={{ size: '5xl' }}
          doubleHeightContent={
            <div>
              some other content <br /> for the widget
            </div>
          }
        />
        <Widget
          title="Average Session Success Rate"
          content={'0%'}
          linkTo="/locations"
          contentProps={{ size: '5xl' }}
          doubleHeightContent={
            <div>
              some other content <br /> for the widget
            </div>
          }
          fullWidth
        />
      </div>
      <Button isLoading size="fullWidth">
        full width
      </Button>
      <br />
      <br />
      <br />
      <Button isLoading variant="secondary">
        Witaj Świecie
      </Button>
      <br />
      <br />
      <br />
      <Button isLoading variant="destructive">
        hello world
      </Button>
      <br />
      <br />
      <br />
      <Button isLoading variant={'ghost'}>
        привіт світ
      </Button>
      <br />
      <br />
      <br />
      <Button isLoading variant="outline">
        مرحبا بالعالم
      </Button>
      <br />
      <br />
      <br />
      <Button isLoading variant="text">
        Hola Mundo <Icon reactIcon={<GearIcon />} />
      </Button>

      <div className="h-screen flex flex-col gap-4 pt-4">
        <Skeleton height={'doubleWidget'} gradientOrigin="center" />
        <div className="flex gap-4">
          <Skeleton height={'widget'} width={'widget'} />
          <Skeleton height={'doubleWidget'} width={'widget'} gradientOrigin={'right'} />
        </div>
        <Skeleton height={'doubleWidget'} />
        <Skeleton height={'tableRow'} gradientOrigin={'right'} />
        <Skeleton height={'tableRow'} />
        <Skeleton height={'tableRow'} gradientOrigin={'right'} />
        <Skeleton height={'tableRow'} />
        <Skeleton height={'tableRow'} gradientOrigin={'right'} />
      </div>
      <div className="mt-4">
        Normal icon
        <Icon reactIcon={<GearIcon width="100" height="100" />} />
        Collored icon with wrapper
        <div className="text-destructive">
          <Icon reactIcon={<GearIcon width="100" height="100" />} />
        </div>
      </div>
    </PageLayout>
  );
};
