import { forwardRef, HTMLAttributes } from 'react';

import { cn } from '@/lib/utils';

const Table = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div className={cn('relative w-full', className)} ref={ref} {...props} />
  ),
);

Table.displayName = 'Table';

const TableRow = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={cn('flex items-center h-14', className)} {...props} />
  ),
);

TableRow.displayName = 'TableRow';

export { Table, TableRow };
